import { useCallback, useEffect, useRef, useState } from "react";
import { RootState } from "../../app/store";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "./../../assets/logo/logo.png";
import { FaSms } from "react-icons/fa";
import { ReactComponent as EmptyWishlist } from "./../../assets/img/take-away-animate.svg";
import { ReactComponent as CheckingBoxes } from "./../../assets/img/checking-boxes-animate.svg";
import { ReactComponent as InNoTime } from "./../../assets/img/in-no-time-animate.svg";
import { ReactComponent as Image } from "./../../assets/img/Images-amico.svg";
import { HashLink as Link } from "react-router-hash-link";
import Banner from "../../components/layout/banner";
import { classNames, useWindowDimensions } from "../../utils/utils";
import { ArrowDropDown, MenuSharp } from "@material-ui/icons";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  pricing_individual_zone_01,
  pricing_individual_zone_02,
  pricing_individual_zone_03,
  pricing_partner_zone_01,
  pricing_partner_zone_02,
  pricing_partner_zone_03,
  pricing_partner_zone_04,
} from "../../data/location";
import {
  dismissNewDeliveryModal,
  dismissWrapUpModal,
  setActiveDelivery,
  toggleNewDeliveryModal,
  toggleWrapUpModal,
} from "../../features/deliveries/deliveriesSlice";
import Modal from "../../components/common/modal";
import NewDelivery from "../../components/ux/new-delivery";
import { TbArrowBadgeRight } from "react-icons/tb";
import DeliveryWrapUp from "../../components/common/new-delivery-wrap-up";
import {
  CallSharp,
  ChevronLeft,
  ChevronRight,
  StoreSharp,
  WhatsApp,
} from "@mui/icons-material";
import { useIsVisible } from "../../hooks/useIsVisible";

let counterIndividual = 0;
let countPartener = 0;
let slideInterval;

function Landing() {
  const navigate = useNavigate();
  const url = useLocation();
  const dispatch = useAppDispatch();
  const [pricing_individual_currentIndex, set_pricing_individual_CurrentIndex] =
    useState(0);
  const [pricing_partner_currentIndex, set_pricing_partner_CurrentIndex] =
    useState(0);
  const topRef = useRef<HTMLDivElement>(null);
  const slideRefTabOne = useRef<HTMLDivElement>(null);
  const slideRefTabTwo = useRef<HTMLDivElement>(null);
  const servicesRef = useRef<HTMLDivElement>(null);
  const pricingRef = useRef<HTMLDivElement>(null);
  const contactRef = useRef<HTMLDivElement>(null);
  const [state, setState] = useState({
    activeSection: "",
  });
  const { width } = useWindowDimensions();
  const isTopVisible = useIsVisible(topRef);
  const isServicesVisible = useIsVisible(servicesRef);
  const isPricingVisible = useIsVisible(pricingRef);
  const isContactVisible = useIsVisible(contactRef);

  const pricing_individual = [
    pricing_individual_zone_01,
    pricing_individual_zone_02,
    pricing_individual_zone_03,
  ];
  const pricing_partner = [
    pricing_partner_zone_01,
    pricing_partner_zone_02,
    pricing_partner_zone_03,
    pricing_partner_zone_04,
  ];

  const location = useAppSelector((state: RootState) => state.utils.location);

  const delivery = useAppSelector(
    (state: RootState) => state?.deliveries?.delivery
  );

  const handlePricingIndividualChevronLeft = () => {
    const size = pricing_individual.length;
    counterIndividual = (pricing_individual_currentIndex + size - 1) % size;
    set_pricing_individual_CurrentIndex(counterIndividual);
  };

  const handlePricingIndividualChevronRight = useCallback(() => {
    counterIndividual = (counterIndividual + 1) % pricing_individual.length;
    set_pricing_individual_CurrentIndex(counterIndividual);
  }, [pricing_individual.length]);

  const handlePricingPartnerChevronLeft = () => {
    const size = pricing_partner.length;
    countPartener = (pricing_partner_currentIndex + size - 1) % size;
    set_pricing_partner_CurrentIndex(countPartener);
  };

  const handlePricingPartnerChevronRight = useCallback(() => {
    countPartener = (countPartener + 1) % pricing_partner.length;
    set_pricing_partner_CurrentIndex(countPartener);
  }, [pricing_individual.length]);

  const startSlider = useCallback(() => {
    slideInterval = setInterval(() => {
      handlePricingIndividualChevronRight();
      handlePricingPartnerChevronRight();
    }, 10000);
  }, [handlePricingIndividualChevronRight, handlePricingPartnerChevronRight]);

  const pauseSlider = () => {
    clearInterval(slideInterval);
  };

  useEffect(() => {
    startSlider();
    if (slideRefTabOne.current || slideRefTabTwo.current) {
      slideRefTabOne?.current?.addEventListener("mouseenter", pauseSlider);
      slideRefTabTwo?.current?.addEventListener("mouseenter", pauseSlider);
      slideRefTabOne?.current?.addEventListener("mouseleave", startSlider);
      slideRefTabTwo?.current?.addEventListener("mouseleave", startSlider);
    }

    return () => {
      clearInterval(slideInterval);
    };
  }, [startSlider]);

  useEffect(() => {
    if (isServicesVisible) {
      navigate("#services");
    }
    if (isPricingVisible) {
      navigate("#pricing");
    }
    if (isContactVisible) {
      navigate("#how-to-contact-us");
    }
  }, [isServicesVisible, isPricingVisible, isContactVisible]);

  const handleCreateDelivery = (e) => {
    e.preventDefault();
    dispatch(setActiveDelivery({ action: "CREATE" }));
    dispatch(toggleNewDeliveryModal());
  };

  const handleJumpToTop = (e) => {
    e.preventDefault();
    if (!isTopVisible) {
      topRef.current?.scrollIntoView({
        behavior: "smooth",
        inline: "start",
        block: "start",
      });
    }
  };

  return (
    <>
      <Modal
        content={<NewDelivery isRadioHiden={true} />}
        className="w-11/12 md:w-3/5"
        modalIsOpen={delivery?.isNewDeliveryModalOpen}
        handleModal={() => dispatch(toggleNewDeliveryModal())}
        dismissModal={() => dispatch(dismissNewDeliveryModal())}
      />
      <Modal
        content={<DeliveryWrapUp />}
        className="w-11/12 md:w-3/5"
        modalIsOpen={delivery?.isWrapUpModalOpened}
        handleModal={() => dispatch(toggleWrapUpModal())}
        dismissModal={() => dispatch(dismissWrapUpModal())}
      />
      <div
        className="mx-auto max-h-screen"
        style={{
          display: "grid",
          gridTemplateColumns: "1fr",
          gridTemplateRows: "5em auto",
        }}
      >
        <div className="fixed top-0 left-0 right-0 z-50 bg-white w-full">
          <Banner className="bg-djeliba" />
          <div
            className="justify-between items-center max-w-full p-2"
            style={{
              display: "grid",
              gridAutoFlow: "column",
              gridTemplateRows: "auto",
            }}
          >
            <img
              onClick={(e) => handleJumpToTop(e)}
              src={logo}
              alt=""
              className="h-14 object-scale-down hover:cursor-pointer"
            />
            <Link to="/products">
              <div className="flex md:invisible rounded hover:cursor-pointer p-2 justify-between items-center min-w-max  hover:text-black hover:bg-white m-2 px-2 border border-djeliba">
                <span>MarketPlace</span>
                <span>
                  <StoreSharp />
                </span>
              </div>
            </Link>
            <div className="relative transition-all ease-linear duration-300 flex justify-center items-center md:invisible group/menu">
              <MenuSharp className="mx-3 " />
              <div className="rounded group-hover/menu:flex-col justify-start items-center hidden group-hover/menu:flex absolute right-9 top-7 bg-black">
                <Link to="#services" smooth>
                  <div className="flex rounded hover:cursor-pointer p-2 justify-between items-center min-w-max text-white  hover:text-black hover:bg-white m-2 px-2 border border-djeliba">
                    <span>Services</span>
                    <span>
                      <ArrowDropDown />
                    </span>
                  </div>
                </Link>
                <Link to="#pricing" smooth>
                  <div className="flex rounded hover:cursor-pointer p-2 justify-between items-center min-w-max text-white  hover:text-black hover:bg-white m-2 px-2 border border-djeliba">
                    <span>Tarifs</span>
                    <span>
                      <ArrowDropDown />
                    </span>
                  </div>
                </Link>
                {/* <Link to="#platform" smooth>
                  <div className="flex rounded hover:cursor-pointer p-2 justify-between items-center min-w-max text-white  hover:text-black hover:bg-white m-2 px-2 border border-djeliba">
                    <span>Plateforme</span>
                    <span>
                      <ArrowDropDown />
                    </span>
                  </div>
                </Link>

                <Link to="#ressources" smooth>
                  <div className="flex rounded hover:cursor-pointer p-2 justify-between items-center min-w-max text-white  hover:text-black hover:bg-white m-2 px-2 border border-djeliba">
                    <span>Ressources</span>
                    <span>
                      <ArrowDropDown />
                    </span>
                  </div>
                </Link> */}
                <Link to="#how-to-contact-us" smooth>
                  <div className="flex rounded hover:cursor-pointer p-2 justify-between items-center min-w-max text-white  hover:text-black hover:bg-white m-2 px-2 border border-djeliba">
                    <span>Nous contacter</span>
                    <span>
                      <ArrowDropDown />
                    </span>
                  </div>
                </Link>
              </div>
            </div>
            <div className="md:flex md:flex-row flex-col justify-center items-center hidden">
              <Link to="/products">
                <div className="flex rounded mx-1 md:mx-2 hover:bg-djeliba hover:cursor-pointer p-2 justify-center items-center hover:shadow-2xl hover:transition-all ease-linear duration-300 hover:text-white">
                  <span>MarketPlace</span>
                  <span>
                    <StoreSharp />
                  </span>
                </div>
              </Link>
              <Link to="#services" smooth>
                <div
                  className={
                    /(^#services)/gm.test(url?.hash)
                      ? classNames(
                          "flex rounded mx-1 md:mx-2 bg-djeliba cursor-pointer p-2 justify-center items-center shadow-2xl transition-all ease-linear duration-300 text-white"
                        )
                      : classNames(
                          "flex rounded mx-1 md:mx-2 hover:bg-djeliba hover:cursor-pointer p-2 justify-center items-center hover:shadow-2xl hover:transition-all ease-linear duration-300 hover:text-white"
                        )
                  }
                >
                  <span>Services</span>
                  <span>
                    <ArrowDropDown />
                  </span>
                </div>
              </Link>
              <Link to="#pricing" smooth>
                <div
                  className={
                    /(^#pricing)/gm.test(url?.hash)
                      ? classNames(
                          "flex rounded mx-1 md:mx-2 bg-djeliba cursor-pointer p-2 justify-center items-center shadow-2xl transition-all ease-linear duration-300 text-white"
                        )
                      : classNames(
                          "flex rounded mx-1 md:mx-2 hover:bg-djeliba hover:cursor-pointer p-2 justify-center items-center hover:shadow-2xl hover:transition-all ease-linear duration-300 hover:text-white"
                        )
                  }
                >
                  <span>Tarifs</span>
                  <span>
                    <ArrowDropDown />
                  </span>
                </div>
              </Link>
              {/* <Link to="#platform" smooth>
                <div className="flex rounded mx-1 md:mx-2 hover:bg-djeliba hover:cursor-pointer p-2 justify-center items-center hover:shadow-2xl hover:transition-all ease-linear duration-300 hover:text-white">
                  <span>Plateforme</span>
                  <span>
                    <ArrowDropDown />
                  </span>
                </div>
              </Link>

              <Link to="#ressources" smooth>
                <div className="flex rounded mx-1 md:mx-2 hover:bg-djeliba hover:cursor-pointer p-2 justify-center items-center hover:shadow-2xl hover:transition-all ease-linear duration-300 hover:text-white">
                  <span>Ressources</span>
                  <span>
                    <ArrowDropDown />
                  </span>
                </div>
              </Link> */}
              <Link to="#how-to-contact-us" smooth>
                <div
                  className={
                    /(^#how-to-contact-us)/gm.test(url?.hash)
                      ? classNames(
                          "flex rounded mx-1 md:mx-2 bg-djeliba cursor-pointer p-2 justify-center items-center shadow-2xl transition-all ease-linear duration-300 text-white"
                        )
                      : classNames(
                          "flex rounded mx-1 md:mx-2 hover:bg-djeliba hover:cursor-pointer p-2 justify-center items-center hover:shadow-2xl hover:transition-all ease-linear duration-300 hover:text-white"
                        )
                  }
                >
                  <span>Nous contacter</span>
                  <span>
                    <ArrowDropDown />
                  </span>
                </div>
              </Link>
            </div>
          </div>
        </div>
        {/* Header */}
        <div className="header"></div>
        {/* Content*/}
        <div className="col-span-full overflow-y-scroll h-screen pt-5">
          <h1
            ref={topRef}
            className="flex font-bold text-4xl md:text-7xl text-center my-7 justify-center items-center"
          >
            La plateforme de distribution destinée exclusivement aux
            vendeurs(es).
          </h1>
          <div
            className="flex flex-col justify-center items-center p-3 m-2"
            ref={servicesRef}
            id="services"
          >
            <Link to="#services" smooth>
              <h2
                className={
                  url?.hash == "#services" || isServicesVisible
                    ? classNames("text-4xl md:text-5xl text-djeliba")
                    : classNames("text-3xl")
                }
              >
                Nos services
              </h2>
            </Link>
            <div className="flex flex-col md:flex-row justify-center items-center p-3 m-2">
              <Link to="#services/distribution" smooth>
                <div className="flex flex-row text-2xl p-1 m-1 list-square md:list-none md:p-2 md:mx-3 md:border rounded hover:bg-djeliba underline md:no-underline hover:no-underline hover:text-white justify-center items-center">
                  <TbArrowBadgeRight className="text-2xl md:hidden" />
                  <span className="text-2xl">Distribution</span>
                </div>
              </Link>
              <Link to="#services/inventory-management" smooth>
                <div className="flex flex-row text-2xl p-1 m-1 list-square md:list-none md:p-2 md:mx-3 md:border rounded hover:bg-djeliba underline md:no-underline hover:no-underline hover:text-white justify-center items-center">
                  <TbArrowBadgeRight className="text-2xl md:hidden" />
                  <span className="text-2xl">Gestion de stock</span>
                </div>
              </Link>
              <Link to="#services/e-commerce" smooth>
                <div className="flex flex-row text-2xl p-1 m-1 list-square md:list-none md:p-2 md:mx-3 md:border rounded hover:bg-djeliba underline md:no-underline hover:no-underline hover:text-white justify-center items-center">
                  <TbArrowBadgeRight className="text-2xl md:hidden" />
                  <span className="text-2xl">E-Commerce</span>
                </div>
              </Link>
            </div>
            <div className="flex flex-col w-full md:flex-row p-2 m-1 md:p-3 md:m-2">
              <div
                id="services/distribution"
                className="flex flex-col md:w-1/2 w-full"
              >
                <div className="flex w-full md:border-b border-djeliba justify-start md:justify-center items-center">
                  <span className="text-2xl">Distribution</span>
                </div>
                {/* Web */}
                <div className="md:flex justify-start items-center w-full p-2 m-2 flex-col hidden h-full">
                  <p className="flex m-3">
                    Grâce à une technologie de pointe et à des pratiques
                    méticuleuses, nous donnons la priorité à l'efficacité et à
                    la précision dans tous les aspects de notre processus de
                    distribution.
                  </p>
                  <p className="flex m-3">
                    Notre service est taillé expressément pour répondre aux
                    besoins des vendeurs(es) en ligne pour qui la distribution
                    reste un enjeu décisif pour la satisfaction des clients.
                  </p>
                  <p className="flex m-3">
                    Une couverture sans faille, permettant une livraison rapide
                    aux clients dans Dakar et sa banlieue ainsi que dans
                    diverses régions.
                  </p>
                </div>
              </div>
              <div className="flex md:w-1/2 w-full md:border-l md:border-t border-djeliba rounded">
                <EmptyWishlist />
              </div>
              <div className="flex justify-start items-center w-full p-2 m-2 flex-col md:hidden h-full">
                <p className="flex m-3">
                  Grâce à une technologie de pointe et à des pratiques
                  méticuleuses, nous donnons la priorité à l'efficacité et à la
                  précision dans tous les aspects de notre processus de
                  distribution.
                </p>
                <p className="flex m-3">
                  Notre service est taillé expressément pour répondre aux
                  besoins des vendeurs(es) en ligne pour qui la distribution
                  reste un enjeu décisif pour la satisfaction des clients.
                </p>
                <p className="flex m-3">
                  Une couverture sans faille, permettant une livraison rapide
                  aux clients dans Dakar et sa banlieue ainsi que dans diverses
                  régions.
                </p>
              </div>
            </div>
            <div className="flex flex-col w-full md:flex-row p-2 m-1 md:p-3 md:m-2">
              <div className="flex w-full md:border-b border-djeliba justify-start md:justify-center items-center md:hidden">
                <span className="text-2xl">Gestion de stock</span>
              </div>
              <div className="flex md:w-1/2 w-full md:border-r md:border-t border-djeliba rounded">
                <CheckingBoxes />
              </div>
              <div
                id="services/inventory-management"
                className="flex flex-col md:w-1/2 w-full"
              >
                {/* Web */}
                <div className="md:flex w-full md:border-b border-djeliba justify-start md:justify-center items-center hidden">
                  <span className="text-2xl">Gestion de stock</span>
                </div>
                <div className="flex justify-start items-center w-full p-2 m-2 flex-col h-full">
                  <p className="flex m-3">
                    Grâce à nos entrepôts stratégiquement situés dans des
                    régions clés, nous garantissons une livraison rapide et
                    rentable à vos clients, où qu'ils se trouvent.
                  </p>
                  <p className="flex m-3">
                    Nous nous occupons du traitement des commandes, la mise à
                    jour des stocks et le suivi des expéditions, ce qui vous
                    permet de vous concentrer sur le développement de nouveau
                    produit, de votre marketing, de la vente etc...
                  </p>
                  <p className="flex m-3">
                    Ce service est tout simplement un autre levier que nous
                    utilisons pour accélérer la distribution des marchandises,
                    avant que votre client(e) ne change d'avis.
                  </p>
                </div>
              </div>
            </div>
            <div className="flex flex-col w-full md:flex-row p-2 m-1 md:p-3 md:m-2">
              <div
                id="services/e-commerce"
                className="flex flex-col md:w-1/2 w-full"
              >
                <div className="flex w-full md:border-b border-djeliba justify-start md:justify-center items-center">
                  <span className="text-2xl">E-Commerce</span>
                </div>
                <div className="md:flex justify-start items-center w-full p-2 m-2 flex-col hidden h-full">
                  <p className="flex m-3">
                    Pour les partenaires qui le souhaitent nous avons une
                    fonctionnalité qui vous permet de rendre accessible au grand
                    public votre catalogue de produits entreposés dans nos
                    locaux.
                  </p>
                  <p className="flex m-3">
                    Ainsi, vos clients pourront accéder encore plus facilement
                    et rapidement à vos produits lors de future commande.
                  </p>
                  <p className="flex m-3">
                    Ceci vous permet à long terme de réduire considérablement
                    votre dépendance et vos dépenses publicitaire sur Facebook
                    ou d'autres réseaux sociaux pour pouvoir vendre.
                  </p>
                </div>
              </div>
              <div className="flex md:w-1/2 w-full md:border-l md:border-t border-djeliba rounded">
                <InNoTime />
              </div>
              <div className="flex justify-start items-center w-full p-2 m-2 flex-col md:hidden h-full">
                <p className="flex m-3">
                  Pour les partenaires qui le souhaitent nous avons une
                  fonctionnalité qui vous permet de rendre accessible au grand
                  public votre catalogue de produits entreposés dans nos locaux.
                </p>
                <p className="flex m-3">
                  Ainsi, vos clients pourront accéder encore plus facilement et
                  rapidement à vos produits lors de future commande.
                </p>
                <p className="flex m-3">
                  Ceci vous permet à long terme de réduire considérablement
                  votre dépendance et vos dépenses publicitaire sur Facebook ou
                  d'autres réseaux sociaux pour pouvoir vendre.
                </p>
              </div>
            </div>
          </div>
          <div
            className="flex flex-col justify-center items-center p-3 m-2"
            ref={pricingRef}
            id="pricing"
          >
            <Link to="#pricing" smooth>
              <h2 className={"text-4xl md:text-5xl text-djeliba"}>
                Notre grille tarifaire
              </h2>
            </Link>
            <div className="flex flex-col justify-center items-center p-3 m-2 md:hidden">
              <Link to="#pricing/partner" smooth>
                <div className="flex flex-row text-2xl p-1 m-1 list-square md:list-none md:p-2 md:mx-3 md:border rounded hover:bg-djeliba underline md:no-underline hover:no-underline hover:text-white justify-center items-center">
                  <TbArrowBadgeRight className="text-2xl md:hidden" />
                  <span className="text-2xl">Tarif partenaire</span>
                </div>
              </Link>
              <Link to="#pricing/individual" smooth>
                <div className="flex flex-row text-2xl p-1 m-1 list-square md:list-none md:p-2 md:mx-3 md:border rounded hover:bg-djeliba underline md:no-underline hover:no-underline hover:text-white justify-center items-center">
                  <TbArrowBadgeRight className="text-2xl md:hidden" />
                  <span className="text-2xl">Tarif particulier</span>
                </div>
              </Link>
            </div>
            <div className="flex max-w-full flex-col md:flex-row justify-center items-center p-2 m-1 md:p-3 md:m-2">
              <div
                id="pricing/partner"
                ref={slideRefTabTwo}
                className="flex w-full min-h-full border md:w-1/2 flex-col justify-center items-center p-2 m-2 bg-djeliba"
              >
                <span className="text-2xl">Tarif partenaire</span>
                <div className="max-h-80 md:min-h-96 text-center overflow-y-scroll scrollbar-hidden">
                  {pricing_partner[pricing_partner_currentIndex]?.locations
                    .sort((a, b) => a.localeCompare(b))
                    .map((item, i) => (
                      <span
                        key={i}
                        className="inline-block border border-black px-2 mx-2 my-1 rounded hover:bg-black hover:text-white hover:cursor-context-menu text-black"
                      >
                        {item}
                      </span>
                    ))}
                </div>
                <div className="relative bg-white bottom-0 left-0 flex flex-col rounded text-center border-2 border-dashed border-black">
                  <span className="text-xl px-8">
                    {pricing_partner[pricing_partner_currentIndex]?.name}
                  </span>
                  <span className="text-xl px-8">
                    {pricing_partner[pricing_partner_currentIndex]?.price}f cfa
                  </span>
                  <div className="absolute w-full top-1/2 transform -translate-y-1/2 flex justify-between items-center">
                    <ChevronLeft
                      onClick={() => handlePricingPartnerChevronLeft()}
                      className="border border-black opacity-50 bg-white rounded hover:cursor-pointer m-1"
                    />
                    <ChevronRight
                      onClick={() => handlePricingPartnerChevronRight()}
                      className="border border-black opacity-50 bg-white rounded hover:cursor-pointer m-1"
                    />
                  </div>
                </div>
              </div>
              <div
                ref={slideRefTabOne}
                id="pricing/individual"
                className="flex w-full min-h-full border md:w-1/2 flex-col justify-center items-center p-2 m-2 bg-black"
              >
                <span className="text-2xl text-white">Tarif particulier</span>
                <div className="max-h-80 md:min-h-96 text-center overflow-y-scroll scrollbar-hidden">
                  {pricing_individual[
                    pricing_individual_currentIndex
                  ]?.locations
                    .sort((a, b) => a.localeCompare(b))
                    .map((item, i) => (
                      <span
                        key={i}
                        className="inline-block border px-2 mx-2 my-1 rounded hover:bg-white hover:text-black text-white hover:cursor-context-menu"
                      >
                        {item}
                      </span>
                    ))}
                </div>
                <div className="relative bg-white flex flex-col rounded text-center border-2 border-dashed border-black">
                  <span className="text-xl px-8">
                    {pricing_individual[pricing_individual_currentIndex]?.name}
                  </span>
                  <span className="text-xl px-8">
                    {pricing_individual[pricing_individual_currentIndex]?.price}
                    f cfa
                  </span>
                  <div className="absolute w-full top-1/2 transform -translate-y-1/2 flex justify-between items-center">
                    <ChevronLeft
                      onClick={() => handlePricingIndividualChevronLeft()}
                      className="border border-black opacity-50 bg-white rounded hover:cursor-pointer m-1"
                    />
                    <ChevronRight
                      onClick={() => handlePricingIndividualChevronRight()}
                      className="border border-black opacity-50 bg-white rounded hover:cursor-pointer m-1"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div
            className="flex flex-col justify-center items-center p-3 m-2"
            id="platform"
          >
            <Link to="#platform" smooth>
              <h2
                className={
                  url?.hash == "#platform"
                    ? classNames("text-4xl md:text-5xl text-djeliba")
                    : classNames("text-3xl")
                }
              >
                La plateforme
              </h2>
            </Link>
          </div>
          <div
            className="flex flex-col justify-center items-center p-3 m-2"
            id="ressources"
          >
            <Link to="#ressources" smooth>
              <h2
                className={
                  url?.hash == "#ressources"
                    ? classNames("text-4xl md:text-5xl text-djeliba")
                    : classNames("text-3xl")
                }
              >
                Les ressourses
              </h2>
            </Link>
          </div> */}
          <div
            className="flex flex-col justify-center items-center p-3 m-2"
            id="how-to-contact-us"
            ref={contactRef}
          >
            <Link to="#how-to-contact-us" smooth>
              <h2 className={"text-4xl md:text-5xl text-djeliba"}>
                Nous contacter
              </h2>
            </Link>
            <div className="flex max-w-full flex-col md:flex-row justify-center items-center p-2 m-1 md:p-3 md:m-2">
              <div className="flex justify-evenly w-60 py-2 border rounded">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`https://wa.me/221785271515?text=Salut%20Djéliba.`}
                >
                  <WhatsApp
                    className="border rounded-md p-1 text-green-500"
                    fontSize="large"
                  />
                </a>
                <a target="_blank" rel="noreferrer" href={`tel:+221785271515`}>
                  <CallSharp
                    className="border rounded-md p-1 text-blue-500"
                    fontSize="large"
                  />
                </a>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`sms:+221785271515?&body=Salut Djéliba.`}
                >
                  <FaSms className="border rounded-md p-1 text-blue-500 text-4xl" />
                </a>
              </div>
            </div>
          </div>
          {/* <div className="border group hover:cursor-pointer fixed flex justify-center items-center bottom-7 md:bottom-5 right-5 p-2 w-14 h-14 bg-white rounded-full active:shadow-xl shadow transition ease-in duration-200 focus:outline-none">
            <div
              onClick={handleCreateDelivery}
              className="flex justify-center items-center bg-djeliba absolute w-12 h-12 rounded-full border group-hover:border-0 group/icon"
            >
              <IoMdAddCircle size={35} className="group-hover:text-slate-400" />
              <div className="min-w-max border border-djeliba absolute m-1 flex right-14 bottom-2 invisible group-hover/icon:visible rounded-md px-1 bg-white ">
                <span className="text-xs font-thin">Nouvelle livraison</span>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
}

export default Landing;
